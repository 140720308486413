import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { reportSample } from '../api/reportService'
import Main from '../Report/components/Main'

export default function Sample() {
  const navigate = useNavigate()
  let { templateId } = useParams()
  const [data, setData] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (templateId === '') {
      navigate('/')
    }
    const fetchData = async () => {
      reportSample(templateId!)
        .then((res) => {
          setData(res)
        })
        .catch((err) => {})
        .finally(() => setIsLoading(false))
    }
    fetchData().then()
  }, [navigate, templateId])

  return <Main data={data} isLoading={isLoading} />
}
