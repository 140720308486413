import React, { useState } from 'react'
import { Center, Text } from '@mantine/core'
import { IconChevronsDown, IconChevronsUp } from '@tabler/icons-react'
import PageEmpty from './PageEmpty'

export default function ExpandableListWrapper({ children, isEmpty }: { children: React.ReactNode; isEmpty: boolean }) {
  const [showAll, setShowAll] = useState(false)
  const toggleShowAll = () => setShowAll(!showAll)
  if (isEmpty) {
    return <PageEmpty image={'/good.png'} title={'暂无数据'} size={68} />
  }
  return (
    <>
      {React.Children.toArray(children)[0]}
      {showAll && React.Children.toArray(children).slice(1)}
      {React.Children.count(children) > 1 && (
        <button onClick={toggleShowAll}>
          {showAll ? (
            <Center>
              <Text c={'#3B84F8'}>收起</Text>
              <IconChevronsUp color={'#3B84F8'} />{' '}
            </Center>
          ) : (
            <Center>
              <Text c={'#3B84F8'}>查看更多</Text>
              <IconChevronsDown color={'#3B84F8'} />{' '}
            </Center>
          )}
        </button>
      )}
    </>
  )
}
