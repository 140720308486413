import { Center, LoadingOverlay, Stack } from '@mantine/core'
import Order from './components/Order'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { getOrders } from '../api/orderService'
import { OrderInterface } from '../typings/orderTypes'
import { CapsuleTabs } from 'antd-mobile'
import PageEmpty from '../components/PageEmpty'
import { getEnumByName } from '../api/dictionaryService'

export default function Orders() {
  const navigate = useNavigate()
  const [orders, setOrders] = useState<OrderInterface[]>()
  const [filterOrders, setFilterOrders] = useState<OrderInterface[]>()
  const [statusMap, setStatusMap] = useState<Map<string, string>>()

  useEffect(() => {
    const fetchData = async () => {
      getOrders()
        .then((data) => {
          setOrders(data)
          setFilterOrders(data)
        })
        .catch(() => {})
    }
    const fetchStatusMap = async () => {
      getEnumByName('com.yuexiangcha.common.enumeration.IsaacOrderStatusEnum')
        .then((data) => {
          setStatusMap(data)
        })
        .catch(() => {})
    }
    fetchData().then()
    fetchStatusMap().then()
  }, [navigate])

  return (
    <div className={'max-w-xl m-auto'}>
      {filterOrders === undefined && (
        <Center>
          <LoadingOverlay zIndex={1000} visible overlayProps={{ radius: 'sm', blur: 2 }} />
        </Center>
      )}
      <CapsuleTabs
        onChange={(value) => {
          setFilterOrders(
            orders?.filter((order) => {
              if (value === 'all') {
                return true
              }
              return order.status.toString() === value
            })
          )
        }}
      >
        <CapsuleTabs.Tab title="全部" key="all" />
        <CapsuleTabs.Tab title="待支付" key="20" />
        <CapsuleTabs.Tab title="生成中" key="30" />
        <CapsuleTabs.Tab title="已退款" key="100" />
        <CapsuleTabs.Tab title="已取消" key="50" />
        <CapsuleTabs.Tab title="已完成" key="40" />
      </CapsuleTabs>
      <Stack p={'sm'}>
        {filterOrders?.map((order) => <Order order={order} key={order.id} statusMap={statusMap!} />)}
      </Stack>
      {filterOrders?.length === 0 && <PageEmpty title={'暂无对应状态的订单'} image={'./empty.png'} />}
    </div>
  )
}
