import { Box, Stack, Text } from '@mantine/core'
import React from 'react'

interface LimitHighConsumptionCaseProps {
  number: number
  item: any
}

export default function ZjcLimitHighConsumptionCase({ number, item }: LimitHighConsumptionCaseProps) {
  return (
    <Box
      bg={'#F1F4FF'}
      p={'xs'}
      style={{
        borderRadius: '4px',
      }}
    >
      <Stack gap={'8'}>
        <Text size={'sm'}>案号: {item?.caseCode}</Text>
        <Text size={'sm'}>法院名称: {item?.courtName}</Text>
        <Text size={'sm'}>立案时间: {item?.caseRegTime}</Text>
        <Text size={'sm'}>发布时间: {item?.casePublishTime}</Text>
      </Stack>
    </Box>
  )
}
