import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ZjcImplementationCases from './ZjcImplementationCases'

interface EnforcementCasesProps {
  data: any
  title: string
}

export default function ZjcEnforcementCases({ data, title }: EnforcementCasesProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ZjcImplementationCases data={data} />
    </ReportWrapper>
  )
}
