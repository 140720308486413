import React from 'react'
import { Stack } from '@mantine/core'

interface ItemWrapperProps {
  children: React.ReactNode
  className?: string
}

export default function ItemWrapper({ children, className }: ItemWrapperProps) {
  const combinedClassName = `bg-white rounded-xl p-3 ${className || ''}`

  return (
    <div className={combinedClassName}>
      <Stack>{children}</Stack>
    </div>
  )
}
