import React, { useEffect, useState } from 'react'
import { Flex, Image, Text } from '@mantine/core'
import Contract from './Contract'

export default function Hero() {
  const [typedText, setTypedText] = useState('')
  const fullText = '悦享查'
  const [isDeleting, setIsDeleting] = useState(false)

  useEffect(() => {
    let timer: NodeJS.Timeout
    const handleTyping = () => {
      const currentLength = typedText.length
      if (isDeleting) {
        if (currentLength === 0) {
          setIsDeleting(false)
        } else {
          setTypedText(fullText.substring(0, currentLength - 1))
        }
      } else {
        if (currentLength === fullText.length) {
          setIsDeleting(true)
        } else {
          setTypedText(fullText.substring(0, currentLength + 1))
        }
      }
    }

    timer = setTimeout(handleTyping, isDeleting ? 200 : 200)

    return () => clearTimeout(timer)
  }, [typedText, isDeleting])

  return (
    <div
      style={{
        height: '625px',
        background: '#1A5AD6',
        padding: '100px',
      }}
    >
      <Flex
        style={{
          width: '1200px',
          margin: '0 auto',
        }}
      >
        <Flex className="relatve " direction="row" gap={100} justify={'space-between'} align={'center'}>
          <Flex gap={30} direction={'column'} align={'flex-start'} flex={1}>
            <div>
              <h2 className="mb-4 text-5xl font-semibold text-white">
                让信用更简单
                <span className={'text-[#f4c80f]'}>&nbsp;{typedText}</span>
              </h2>
              <Text size={'16px'} c={'#fff'} lh={'28px'} mt={'50px'}>
                悦享查，让信用更简单，通过自研的大数据风控模型分析和深度学习算法，可为用户提供全面的信用评估报告和定制化的信用提升计划。
                对于租赁、家政、背调等行业服务机构，提供定制化的信用评估产品和技术支持。
              </Text>
            </div>
            <button
              onClick={() => {
                window.open('https://yuexiangcha.com/queryReport?linkId=10140504619395260417')
              }}
              style={{
                background: '#fff',
                color: '#336df4',
                padding: '10px 20px',
                margin: '10px',
                border: '1px solid #4e83fd',
                borderRadius: '40px',
                width: '176px',
                fontWeight: '500',
                fontSize: '20px',
              }}
            >
              立即查询
            </button>
          </Flex>
          <div>
            <Image
              w={'542px'}
              h={'508px'}
              src="https://yuexiangcha-1327893205.cos.ap-guangzhou.myqcloud.com/website/head.jpg"
            />
          </div>
        </Flex>
      </Flex>
    </div>
  )
}
