import { Flex, Group, Image, Text } from '@mantine/core'
import React, { useState } from 'react'
import Contract from './Contract'

interface HeaderProps {
  menus: any[]
}

export default function Header({ menus }: HeaderProps) {
  const [activeSection, setActiveSection] = useState<string>('')
  const handleMenuClick = (section: string, scrollFn: Function) => {
    setActiveSection(section)
    scrollFn()
  }
  return (
    <header
      style={{
        width: '100%',
        zIndex: 1000,
        position: 'fixed',
        background: '#fff',
      }}
    >
      <div
        style={{
          margin: '0 auto',
          padding: '0',
          width: '1200px',
        }}
      >
        <Flex direction={'row'} justify={'space-between'}>
          <Group gap={30}>
            <Image
              src={'https://yuexiangcha-1327893205.cos.ap-guangzhou.myqcloud.com/website/logo-h-2.png'}
              h={'60px'}
            />
            {menus.map((menu) => (
              <Text
                fw={600}
                lh={'22px'}
                size={'16px'}
                p={'24px'}
                className={'cursor-pointer'}
                style={{
                  borderBottom: `4px solid ${activeSection === menu.section ? '#195AD6' : 'transparent'}`,
                  color: activeSection === menu.section ? '#195AD6' : '#333',
                }}
                onClick={() => handleMenuClick(menu.section, menu.scrollFn)}
              >
                {menu.label}
              </Text>
            ))}
          </Group>
          <Group gap={30}>
            <Group>
              <button
                style={{
                  width: '100px',
                  height: '36px',
                  borderRadius: '100px',
                  border: '1px solid #1A59D4',
                  color: '#1A59D4',
                  fontWeight: '500',
                  fontSize: '16px',
                }}
              >
                下载App
              </button>
            </Group>
            <Group>
              <Contract>
                <button
                  style={{
                    width: '100px',
                    height: '36px',
                    borderRadius: '100px',
                    background: 'linear-gradient(90deg,#4e83fd 0,#3370ff 100%)',
                    color: '#fff',
                    fontWeight: '500',
                    fontSize: '16px',
                  }}
                >
                  联系我们
                </button>
              </Contract>
            </Group>
          </Group>
        </Flex>
      </div>
    </header>
  )
}
