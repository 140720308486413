import React from 'react'
import { Center, Image } from '@mantine/core'

export default function ServiceChatWrapper({ children }: { children: React.ReactNode }) {
  return (
    <>
      {children}
      <Center
        className={'fixed right-[10px] bottom-[20px]'}
        onClick={() => {
          window.open('https://work.weixin.qq.com/kfid/kfce9f9972edfa07d21')
        }}
        w={60}
        h={60}
      >
        <Image src={'/chat.png'} alt={'wechat'} />
      </Center>
    </>
  )
}
