import React from 'react'
import { Image } from '@mantine/core'

interface FloatingButtonProps {
  image?: string
  onClick?: () => void
}

const FloatingButton: React.FC<FloatingButtonProps> = ({ image, onClick }) => {
  return <Image src={image} onClick={onClick} w={'50%'} />
}

export default FloatingButton
