import LabeledTitle from '../../../components/LabeledTitle'
import HitItem from '../HitItem'
import ItemWrapper from '../../../components/ItemWrapper'

interface BankOutOfConnectionFraudProps {
  data: any
}

export default function BankOutOfConnectionFraud({ data }: BankOutOfConnectionFraudProps) {
  return (
    <ItemWrapper>
      <LabeledTitle title={'银行失联欺诈行为'} />
      <HitItem
        name={'银行失联欺诈'}
        value={data?.als_yhslqz_count > 0 ? '命中' : '未命中'}
        isNormal={data?.als_yhslqz_count === 0}
        hitCount={data?.als_yhslqz_count || 0}
        recentHitTime={data?.als_yhslqz_count === 0 ? '-' : `近${data?.als_yhslqz_duration + 1}年内`}
      />
      <HitItem
        name={'银行90天以内异常'}
        value={data?.als_d90_yq_count > 0 ? '命中' : '未命中'}
        isNormal={data?.als_d90_yq_count === 0}
        hitCount={data?.als_d90_yq_count || 0}
        recentHitTime={data?.als_d90_yq_count === 0 ? '-' : `近${data?.als_d90_yq_duration + 1}年内`}
      />
      <HitItem
        name={'银行90天以上异常'}
        value={data?.als_gt_d90_yq_count > 0 ? '命中' : '未命中'}
        isNormal={data?.als_gt_d90_yq_count === 0}
        hitCount={data?.als_gt_d90_yq_count || 0}
        recentHitTime={data?.als_gt_d90_yq_count === 0 ? '-' : `近${data?.als_gt_d90_yq_duration + 1}年内`}
      />
    </ItemWrapper>
  )
}
