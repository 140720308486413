import LabeledTitle from '../../../components/LabeledTitle'
import React from 'react'
import ZjcCourtCase from './ZjcCourtCase'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'
import ItemWrapper from '../../../components/ItemWrapper'

export default function ZjcOtherCases({ data }: { data: any }) {
  return (
    <ItemWrapper>
      <LabeledTitle title={'其他案件检测'} />
      <ExpandableListWrapper
        isEmpty={(data?.court_case_info?.other ?? []).length === 0 || data?.court_case_info?.other === undefined}
      >
        {(data?.court_case_info?.other ?? []).map((item: any, index: number) => {
          return <ZjcCourtCase number={index + 1} key={index} item={item} />
        })}
      </ExpandableListWrapper>
    </ItemWrapper>
  )
}
