import LabeledTitle from '../../../components/LabeledTitle'
import ReportItem from '../ReportItem'
import ItemWrapper from '../../../components/ItemWrapper'

interface RelatedPartyBankFailureFraudProps {
  data: any
}

export default function RelatedPartyBankFailureFraud({ data }: RelatedPartyBankFailureFraudProps) {
  return (
    <ItemWrapper>
      <LabeledTitle title={'关联人银行失联欺诈行为检测'} />
      <ReportItem
        name={'是否有银行失联欺诈行为'}
        value={data?.aals_00001 === 1 ? '是' : '否'}
        isNormal={data?.aals_00001 !== 1}
      />
    </ItemWrapper>
  )
}
