import LabeledTitle from '../../../components/LabeledTitle'
import React from 'react'
import EnforcementCase from './EnforcementCase'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'
import ItemWrapper from '../../../components/ItemWrapper'

interface ImplementationCasesProps {
  data: any
}

export default function ImplementationCases({ data }: ImplementationCasesProps) {
  return (
    <ItemWrapper>
      <LabeledTitle title={'执行关联案件检测'} />
      <ExpandableListWrapper isEmpty={(data?.exec_case_info ?? []).length === 0 || data?.exec_case_info === undefined}>
        {(data?.exec_case_info ?? []).map((item: any, index: number) => {
          return <EnforcementCase number={index + 1} key={index} item={item} />
        })}
      </ExpandableListWrapper>
    </ItemWrapper>
  )
}
