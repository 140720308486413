import { Divider, Flex, Group, SimpleGrid, Stack, Text } from '@mantine/core'
import React, { useState } from 'react'
import Contract from './Contract'

export default function Solution() {
  const [selected, setSelected] = useState(0)
  const solutions = [
    {
      title: '个人风险',
      basic: [
        '报告分析指数',
        '身份信息核验',
        '涉诉案件扫描',
        '失信案件扫描',
        '执行案件扫描',
        '限制高消费信息',
        '社交标签',
      ],
      standard: [
        '报告分析指数',
        '身份信息核验',
        '银行贷款类申请记录',
        '非银贷款类申请记录',
        '异常名单扫描',
        '关联异常名单扫描',
        '涉诉案件扫描',
        '失信案件扫描',
        '执行案件扫描',
        '限制高消费信息',
        '社交标签',
      ],
      plus: [
        '报告分析指数',
        '身份信息核验',
        '银行贷款类申请记录',
        '非银贷款类申请记录',
        '异常名单扫描',
        '关联异常名单扫描',
        '重点人员核查',
        '涉诉案件扫描',
        '失信案件扫描',
        '执行案件扫描',
        '限制高消费信息',
        '社交标签',
      ],
    },
    {
      title: '人才背调',
      basic: [
        '报告分析指数',
        '身份信息核验',
        '学历',
        '履历评估',
        '异常名单扫描',
        '涉诉案件扫描',
        '失信案件扫描',
        '执行案件扫描',
        '限制高消费信息',
      ],
      standard: [
        '报告分析指数',
        '身份信息核验',
        '学历',
        '履历评估',
        '银行贷款类申请记录',
        '非银贷款类申请记录',
        '异常名单扫描',
        '关联异常名单扫描',
        '涉诉案件扫描',
        '失信案件扫描',
        '执行案件扫描',
        '限制高消费信息',
      ],
      plus: [
        '报告分析指数',
        '身份信息核验',
        '学历',
        '履历评估',
        '银行贷款类申请记录',
        '非银贷款类申请记录',
        '异常名单扫描',
        '关联异常名单扫描',
        '重点人员核查',
        '涉诉案件扫描',
        '失信案件扫描',
        '执行案件扫描',
        '限制高消费信息',
      ],
    },
    {
      title: '家政服务',
      basic: [
        '报告分析指数',
        '身份信息核验',
        '涉诉案件扫描',
        '失信案件扫描',
        '执行案件扫描',
        '限制高消费信息',
        '重点人员核查',
      ],
      standard: [
        '报告分析指数',
        '身份信息核验',
        '异常名单扫描',
        '关联异常名单扫描',
        '涉诉案件扫描',
        '失信案件扫描',
        '执行案件扫描',
        '限制高消费信息',
        '重点人员核查',
      ],
      plus: [
        '报告分析指数',
        '身份信息核验',
        '银行贷款类申请记录',
        '非银贷款类申请记录',
        '异常名单扫描',
        '关联异常名单扫描',
        '涉诉案件扫描',
        '失信案件扫描',
        '执行案件扫描',
        '限制高消费信息',
        '重点人员核查',
      ],
    },
    {
      title: '租赁服务',
      basic: [
        '报告分析指数',
        '身份信息核验',
        '行业黑名单',
        '涉诉案件扫描',
        '失信案件扫描',
        '执行案件扫描',
        '限制高消费信息',
        '重点人员核查',
        '社交标签',
      ],
      standard: [
        '报告分析指数',
        '身份信息核验',
        '异常名单扫描',
        '关联异常名单扫描',
        '行业黑名单',
        '涉诉案件扫描',
        '失信案件扫描',
        '执行案件扫描',
        '限制高消费信息',
        '重点人员核查',
        '社交标签',
      ],
      plus: [
        '报告分析指数',
        '身份信息核验',
        '银行贷款类申请记录',
        '非银贷款类申请记录',
        '异常名单扫描',
        '关联异常名单扫描',
        '行业黑名单',
        '涉诉案件扫描',
        '失信案件扫描',
        '执行案件扫描',
        '限制高消费信息',
        '重点人员核查',
        '社交标签',
      ],
    },
    {
      title: '小微企业',
      basic: [
        '评估指数',
        '企业基本信息',
        '企业⼯商信息',
        '企业核⼼管理成员',
        '企业现股东信息',
        '企业历史股东信息',
        '企业⾏政处罚信息',
        '企业⽋税信息详情',
        '企业变更记录',
        '企业融资信息',
        '企业经营信息摘要',
        '企业动产抵押详情',
        '企业信⽤评级与税务评级',
        '企业参保信息',
        '企业重要统计分析',
        '企业知识产权信息',
      ],
      standard: [
        '评估指数',
        '企业基本信息',
        '企业⼯商信息',
        '企业核⼼管理成员',
        '企业现股东信息',
        '企业历史股东信息',
        '企业⾏政处罚信息',
        '企业⽋税信息详情',
        '企业变更记录',
        '对外投资',
        '企业融资信息',
        '企业对外担保详情',
        '企业经营信息摘要',
        '企业动产抵押详情',
        '企业信⽤评级与税务评级',
        '企业参保信息',
        '企业重要统计分析',
        '企业知识产权信息',
      ],
      plus: [
        '评估指数',
        '企业基本信息',
        '企业⼯商信息',
        '企业核⼼管理成员',
        '企业现股东信息',
        '企业历史股东信息',
        '企业⾏政处罚信息',
        '企业⽋税信息详情',
        '企业变更记录',
        '对外投资',
        '企业银⾏信息',
        '企业融资信息',
        '企业对外担保详情',
        '企业经营信息摘要',
        '企业动产抵押详情',
        '企业信⽤评级与税务评级',
        '企业参保信息',
        '企业重要统计分析',
        '企业知识产权信息',
        '查询记录汇总明细',
      ],
    },
  ]
  const SolutionItem = ({
    title,
    isSelected,
    onSelected,
    index,
  }: {
    title: string
    index: number
    isSelected: boolean
    onSelected: (index: number) => void
  }) => {
    return (
      <div>
        <Stack className={'cursor-pointer'} onClick={() => onSelected(index)}>
          <Text
            size={'20px'}
            fw={800}
            c={'#1f2329'}
            lh={'25px'}
            py={'27px'}
            style={{
              borderTop: isSelected ? '2px solid #104aba' : 'none',
            }}
          >
            {title}
          </Text>
        </Stack>
      </div>
    )
  }
  return (
    <Stack align={'center'} gap={'120px'}>
      <Text size={'40px'} fw={'500'} c={'#1f2329'}>
        各行各业都有解决方案
      </Text>
      <Stack w={'1200px'}>
        <SimpleGrid cols={5} spacing={'xl'} verticalSpacing={'xl'}>
          {solutions.map((solution, index) => (
            <SolutionItem
              key={index}
              title={solution.title}
              isSelected={selected === index}
              onSelected={setSelected}
              index={index}
            />
          ))}
        </SimpleGrid>
        <Group>
          <Stack
            w={'281px'}
            h={'850px'}
            bg={'#fff'}
            style={{
              boxShadow: '0 8px 28px 0 rgba(9,37,64,.06)',
              borderRadius: '12px',
              border: '1px solid #dbdfe7',
              padding: '32px 24px',
            }}
          >
            <Text size={'24px'} fw={600} c={'#111'} lh={'32px'}>
              基础版
            </Text>
            <Divider variant={'dashed'} />
            <Flex direction={'row'} wrap={'wrap'} gap={'20px'}>
              {solutions[selected].basic.map((item, index) => (
                <Flex key={index} align={'center'} gap={'10px'}>
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      background: 'linear-gradient(90deg,#4e83fd 0,#3370ff 100%)',
                    }}
                  />
                  <Text size={'16px'} fw={600} c={'#111'} lh={'22px'}>
                    {item}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Stack>
          <Stack
            w={'281px'}
            h={'850px'}
            bg={'#fff'}
            style={{
              boxShadow: '0 8px 28px 0 rgba(9,37,64,.06)',
              borderRadius: '12px',
              border: '1px solid #dbdfe7',
              padding: '32px 24px',
            }}
          >
            <Text size={'24px'} fw={600} c={'#111'} lh={'32px'}>
              标准版
            </Text>
            <Divider variant={'dashed'} />
            <Flex direction={'row'} wrap={'wrap'} gap={'20px'}>
              {solutions[selected].standard.map((item, index) => (
                <Flex key={index} align={'center'} gap={'10px'}>
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      background: 'linear-gradient(90deg,#4e83fd 0,#3370ff 100%)',
                    }}
                  />
                  <Text size={'16px'} fw={600} c={'#111'} lh={'22px'}>
                    {item}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Stack>
          <Stack
            w={'281px'}
            h={'850px'}
            bg={'#fff'}
            style={{
              boxShadow: '0 8px 28px 0 rgba(9,37,64,.06)',
              borderRadius: '12px',
              border: '1px solid #dbdfe7',
              padding: '32px 24px',
            }}
          >
            <Text size={'24px'} fw={600} c={'#111'} lh={'32px'}>
              升级版
            </Text>
            <Divider variant={'dashed'} />
            <Flex direction={'row'} wrap={'wrap'} gap={'20px'}>
              {solutions[selected].plus.map((item, index) => (
                <Flex key={index} align={'center'} gap={'10px'}>
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      background: 'linear-gradient(90deg,#4e83fd 0,#3370ff 100%)',
                    }}
                  />
                  <Text size={'16px'} fw={600} c={'#111'} lh={'22px'}>
                    {item}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Stack>
          <Stack
            w={'281px'}
            h={'850px'}
            justify={'center'}
            style={{
              boxShadow: '0 8px 28px 0 rgba(9,37,64,.06)',
              borderRadius: '12px',
              background: 'linear-gradient(180deg,#104aba,#1d5edc)',
              padding: '0 24px',
            }}
          >
            <Text c={'#fff'} size={'24px'} fw={'600'}>
              咨询行业专家顾问
            </Text>
            <Text c={'#fff'}>悦享查可根据您所在行业、需求定制符合您企业的方案</Text>
            <Contract>
              <button
                style={{
                  width: '144px',
                  height: '44px',
                  borderRadius: '24px',
                  border: '1px solid #1A59D4',
                  background: '#fff',
                  color: '#1A59D4',
                  fontWeight: '500',
                  fontSize: '16px',
                }}
              >
                联系我们
              </button>
            </Contract>
          </Stack>
        </Group>
      </Stack>
    </Stack>
  )
}
