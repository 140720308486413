import React from 'react'
import './index.css'

const Stamp = ({ text, isNormal }: { text: string; isNormal?: boolean }) => {
  return (
    <div
      className="stamp"
      style={{
        backgroundColor: isNormal ? '#10932F' : 'red',
        color: '#fff',
      }}
    >
      {text}
    </div>
  )
}

export default Stamp
