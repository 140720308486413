import styles from './Service.module.css'

interface PowerOfAttorneyProps {
  merchantPhone?: String | undefined
  name?: String | undefined
  idCard?: String | undefined
}

export default function PowerOfAttorney({ merchantPhone, name, idCard }: PowerOfAttorneyProps) {
  return (
    <div id="app" className={styles.customParagraphStyle}>
      <div>
        <div className="div">
          <p style={{ textAlign: 'center' }}>
            <strong>授权书</strong>
          </p>
          <p style={{ textAlign: 'left' }}>
            <span>四川陨石科技有限公司：</span>
          </p>
          <p style={{ textIndent: 24, textAlign: 'left' }}>
            <span>本人（</span>
            <span style={{ textDecoration: 'underline' }}>{name || ' '}</span>
            <span>（姓名）／</span>
            <span style={{ textDecoration: 'underline' }}>{idCard || ' '}</span>
            <span>(</span>
            <span>身份证号码）</span>
            <span>)</span>
            <span>
              拟向贵司申请大数据分析报告查询业务，贵司需要了解本人相关状况，用于查询大数据分析报告，因此本人同意向贵司提供本人的姓名和手机号等个人信息，并同意贵司向第三方（包括但不限于成都市公共数据运营服务平台）传送上述信息，第三方将使用上述信息核实信息真实情况，查询信用记录，并生成报告。
            </span>
          </p>
          <p style={{ textIndent: 24, textAlign: 'left' }}>
            <span>
              （一）贵司向依法成立的第三方服务商（包括但不限于成都市公共数据运营服务平台）根据本人提交的信息进行核实；并有权通过前述第三方服务机构查询、使用本人的身份信息、设备信息、运营商信息等，查询本人信息（包括但不限于学历、婚姻、资产状况及对信息主体产生负面影响的不良信息），出具相关报告。
            </span>
          </p>
          <p style={{ textIndent: 24, textAlign: 'left' }}>
            <span>
              （二）依法成立的第三方服务商查询或核实、搜集、保存、处理、共享、使用（含合法业务应用）本人相关数据，且不再另行告知本人，但法律、法规、监管政策禁止的除外。
            </span>
          </p>
          <p style={{ textIndent: 24, textAlign: 'left' }}>
            <span>
              （三）本人同意并授权四川陨石科技有限公司根据法律法规和监管部门的要求和业务需要保留上述相关资料(包括影像资料)，保存期限为本授权书生效之日起30个自然日。法律、行政法规、政府规章、监管规范对客户个人信息资料有更长保存期限要求的，遵守其规定。
            </span>
          </p>
          <p style={{ textIndent: 24, textAlign: 'left' }}>
            <span>
              本人在此声明已充分理解上述授权条款含义，同意上述授权及应用，知晓并自愿承担上述因收集等本人数据所导致的风险。
            </span>
          </p>
          <p style={{ textIndent: 24, textAlign: 'left' }}>
            <span>{`本人授权本业务推广方(${merchantPhone || '****'})可浏览本人大数据报告。`}</span>
          </p>
          <p style={{ textIndent: 24, textAlign: 'left' }}>
            <span>特别提示：</span>
          </p>
          <p style={{ textIndent: 24, textAlign: 'left' }}>
            <span>
              为了保障您的合法权益，请您务必阅读并充分理解与遵守本授权书；若您不接受本授权书的任何条款，请您立即终止授权。贵司已经对上述事宜及其风险向本人做了充分说明，本人已知晓并同意。
            </span>
          </p>
          <p style={{ textIndent: 24, textAlign: 'left' }}>
            <span>
              你通过“悦享查”APP或推广方推广查询模式，自愿支付相应费用，用于购买四川陨石科技有限公司的大数据报告产品，如若对该产品内容存在异议，可通过“联系客服”按钮进行反馈，我们将在自收到异议之日起20日内进行核查和处理，并将结果进行答复。
            </span>
          </p>
          <p style={{ textIndent: 24, textAlign: 'left' }}>
            <span>
              你向四川陨石科技有限公司的支付方式为：四川陨石科技有限公司及其经官方授权的相关企业的支付宝账户。
            </span>
          </p>
          <p style={{ textIndent: 24, textAlign: 'left', fontWeight: 'bold' }}>
            <span>
              本授权书一经本人在网上点击勾选同意即完成签署。本授权书是本人真实意思表示，本人同意承担由此带来的一切法律后果。
            </span>
          </p>
          <p style={{ textIndent: 24, textAlign: 'right' }}>
            <span>{`本授权书于${new Date().getFullYear()}年${new Date().getMonth() + 1}月${new Date().getDate()}日生效`}</span>
          </p>
        </div>
      </div>
    </div>
  )
}
