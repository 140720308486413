import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ZjcRelatedCasesStatistics from './ZjcRelatedCasesStatistics'
import ZjcCriminalCases from './ZjcCriminalCases'
import ZjcCivilCases from './ZjcCivilCases'
import ZjcOtherCases from './ZjcOtherCases'

interface ZjcCourtCasesProps {
  data: any
  title: string
}

export default function ZjcCourtCases({ data, title }: ZjcCourtCasesProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ZjcRelatedCasesStatistics data={data} />
      <ZjcCriminalCases data={data} />
      <ZjcCivilCases data={data} />
      <ZjcOtherCases data={data} />
    </ReportWrapper>
  )
}
