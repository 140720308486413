import Hero from './components/Hero'
import Feature from './components/Feature'
import Footer from './components/Footer'
import React from 'react'
import Header from './components/Header'
import CTA from './components/CTA'
import Support from './components/Support'
import Partner from './components/Partner'
import Solution from './components/Solution'
import { Stack } from '@mantine/core'
import { useScrollIntoView } from '@mantine/hooks'

export default function Home() {
  const { scrollIntoView: scrollToHeader, targetRef: headerRef } = useScrollIntoView({ offset: 100 })
  const { scrollIntoView: scrollToFeature, targetRef: featureRef } = useScrollIntoView({ offset: 100 })
  const { scrollIntoView: scrollToSolution, targetRef: solutionRef } = useScrollIntoView({ offset: 100 })
  const { scrollIntoView: scrollToSupport, targetRef: supportRef } = useScrollIntoView({ offset: 100 })

  const menus = [
    {
      label: '首页',
      link: '/',
      section: 'hero',
      scrollFn: scrollToHeader,
    },
    {
      label: '产品服务',
      link: '/',
      section: 'feature',
      scrollFn: scrollToFeature,
    },
    {
      label: '解决方案',
      link: '/',
      section: 'solution',
      scrollFn: scrollToSolution,
    },
    {
      label: '合作与支持',
      link: '/',
      section: 'support',
      scrollFn: scrollToSupport,
    },
  ]

  return (
    <div className={'min-w-[1200px]'}>
      <section ref={headerRef}>
        <Header menus={menus} />
      </section>
      <Hero />
      <div
        className={'py-[120px]'}
        style={{
          backgroundImage:
            'url(https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/ec191307bf1d42679375949abd0afa38~tplv-jbbdkfciu3-png:0:0.png)',
          backgroundClip: 'padding-box',
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'scroll',
          backgroundPosition: 'top center',
          backgroundBlendMode: 'normal',
        }}
      >
        <Stack>
          <section ref={featureRef}>
            <Feature />
          </section>
          <section ref={solutionRef}>
            <Solution />
          </section>
        </Stack>
      </div>
      <Partner />
      <section ref={supportRef}>
        <Support />
      </section>
      <CTA />
      <Footer />
    </div>
  )
}
