import React from 'react'
import { Card, ScrollArea, Space, Table, Text, Title } from '@mantine/core'

const agentLevels = [
  {
    level: '1星代理商',
    withdrawalRights: '每天可提现1次，单次提现超过1000元需要后台审核',
    promotionEarnings: '1元',
    upgradeConditions: '/',
    maintenanceRules: '/',
  },
  {
    level: '2星代理商',
    withdrawalRights: '每天可提现2次，单次提现超过1000元需要后台审核',
    promotionEarnings: '1.1元',
    upgradeConditions: '前30天内下级代理或自己产出报告份数超过10份；且直接收益订单30天内无B类以上违规（含B类）',
    maintenanceRules:
      '前60天内下级代理或自己产出报告份数超过30份，直接收益订单30天内无B类以上违规（含B类），不满足将降级为1星代理商',
  },
]

export default function UpgradeRule() {
  const rows = agentLevels.map((level) => (
    <tr key={level.level} className="hover:bg-gray-100">
      <td className="py-2 px-2 sm:py-4 sm:px-6 border-b">{level.level}</td>
      <td className="py-2 px-2 sm:py-4 sm:px-6 border-b">{level.withdrawalRights}</td>
      <td className="py-2 px-2 sm:py-4 sm:px-6 border-b">{level.promotionEarnings}</td>
      <td className="py-2 px-2 sm:py-4 sm:px-6 border-b">{level.upgradeConditions}</td>
      <td className="py-2 px-2 sm:py-4 sm:px-6 border-b">{level.maintenanceRules}</td>
    </tr>
  ))

  return (
    <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
      <Title order={2} className="text-center mb-4 sm:mb-8 text-xl sm:text-2xl">
        悦享查代理商升星体系
      </Title>
      <Card shadow="sm" padding="sm" className="mb-4 sm:mb-8">
        <Text className="text-sm sm:text-base mb-4 sm:mb-8">
          本页面详细说明了代理商的各个星级、对应权益、晋升条件和保级规则。
        </Text>
      </Card>
      <ScrollArea>
        <Table striped highlightOnHover className="w-full text-xs sm:text-sm">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-2 px-2 sm:py-4 sm:px-6 text-left">代理商星级</th>
              <th className="py-2 px-2 sm:py-4 sm:px-6 text-left">提现权益</th>
              <th className="py-2 px-2 sm:py-4 sm:px-6 text-left">推广收益</th>
              <th className="py-2 px-2 sm:py-4 sm:px-6 text-left">晋升条件</th>
              <th className="py-2 px-2 sm:py-4 sm:px-6 text-left">保级规则</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
      <Space h="xl" />
      <Card shadow="sm" padding="sm">
        <Title order={4} className="text-base sm:text-lg">
          备注：
        </Title>
        <Text className="text-xs sm:text-sm">1. 升星成功后不影响历史订单的推广收益（只影响升星成功后发生的订单）</Text>
        <Text className="text-xs sm:text-sm">2. 降星后不影响历史订单的推广收益（只影响降星后发生的订单）</Text>
      </Card>
    </div>
  )
}
