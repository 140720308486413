import { Box, Stack, Text } from '@mantine/core'
import React from 'react'

interface FailureComplyCaseProps {
  number: number
  item: any
}

export default function ZjcFailureComplyCase({ number, item }: FailureComplyCaseProps) {
  return (
    <Box
      bg={'#F1F4FF'}
      p={'xs'}
      style={{
        borderRadius: '4px',
      }}
    >
      <Stack gap={'8'}>
        <Text size={'sm'}>案号: {item?.caseCode}</Text>
        <Text size={'sm'}>法院名称: {item?.courtName}</Text>
        <Text size={'sm'}>立案时间: {item?.caseRegTime}</Text>
        <Text size={'sm'}>曝光时间: {item?.casePublishTime}</Text>
        <Text size={'sm'}>曝光信号描述: {item?.caseSignalDesc}</Text>
      </Stack>
    </Box>
  )
}
