import LabeledTitle from '../../../components/LabeledTitle'
import ReportItem from '../ReportItem'
import ItemWrapper from '../../../components/ItemWrapper'

interface RelatedPartyOnlineLoanFraudProps {
  data: any
}

export default function RelatedPartyOnlineLoanFraud({ data }: RelatedPartyOnlineLoanFraudProps) {
  return (
    <ItemWrapper>
      <LabeledTitle title={'关联人网贷欺诈关联行为'} />
      <ReportItem
        name={'是否有网络小贷欺诈关联'}
        value={data?.aals_00011 === 1 ? '是' : '否'}
        isNormal={data?.aals_00011 !== 1}
      />
      <ReportItem
        name={'是否有地方小贷欺诈关联'}
        value={data?.aals_00012 === 1 ? '是' : '否'}
        isNormal={data?.aals_00012 !== 1}
      />
      <ReportItem
        name={'是否有消费金融公司欺诈关联'}
        value={data?.aals_00013 === 1 ? '是' : '否'}
        isNormal={data?.aals_00013 !== 1}
      />
      <ReportItem
        name={'是否有融资租赁公司欺诈关联'}
        value={data?.aals_00014 === 1 ? '是' : '否'}
        isNormal={data?.aals_00014 !== 1}
      />
      <ReportItem
        name={'是否有汽车金融公司欺诈关联'}
        value={data?.aals_00015 === 1 ? '是' : '否'}
        isNormal={data?.aals_00015 !== 1}
      />
      <ReportItem
        name={'是否有其他金融公司欺诈关联'}
        value={data?.aals_00016 === 1 ? '是' : '否'}
        isNormal={data?.aals_00016 !== 1}
      />
    </ItemWrapper>
  )
}
