import { Container, Flex, Stack, Text } from '@mantine/core'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import ItemWrapper from '../../../components/ItemWrapper'
import ReportScore from './ReportScore'
import ReportDetail from './ReportDetail'
import LabeledContainer from '../../../components/LabeledContainer'
import LabeledTitle from '../../../components/LabeledTitle'
import { getLevelDesc } from '../ReportUtils'

interface ReportOverviewProps {
  data: any
  title: string
}

export default function ReportOverview({ data, title }: ReportOverviewProps) {
  return (
    <ReportWrapper>
      <ItemWrapper className={'z-50'}>
        <Stack>
          <ReportScore data={data} />
          <Container bg={'#174AFE'} className={'rounded-xl'}>
            <Text c={'#fff'}>{getLevelDesc(data)}</Text>
          </Container>
          <Text size={'sm'}>
            根据网络行为大数据出具的分析评估参考分数，分数越高越好。该指数仅对本报告有效，不代表对报告查询人的综合定性评价。
          </Text>
        </Stack>
      </ItemWrapper>
      <LabeledContainer title={'报告概览'} />
      <ItemWrapper>
        <Flex justify={'space-between'}>
          <LabeledTitle title={'查询内容'} />
          <Text size={'sm'}>报告生成时间：{data?.meta_info_date}</Text>
        </Flex>
        <ReportDetail data={data} />
      </ItemWrapper>
    </ReportWrapper>
  )
}
