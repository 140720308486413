export const getStampStr = (data: any) => {
  return data?.meta_index_score < 30 ? '建议拒绝' : data?.meta_index_score < 60 ? '建议谨慎' : '建议通过'
}

export const getLevelStr = (data: any) => {
  const value = data?.meta_index_score
  if (value < 30) {
    return '风险等级： E'
  }
  if (value < 40) {
    return '风险等级： D'
  }
  if (value < 50) {
    return '风险等级： C'
  }
  if (value < 60) {
    return '风险等级： B'
  }
  return '风险等级： A'
}

export const getLevelDesc = (data: any) => {
  const value = data?.meta_index_score
  if (value < 30) {
    return getLevelStr(data) + '，属于风险高危的区域，建议拒绝。'
  }
  if (value < 60) {
    return getLevelStr(data) + '，属于风险较高的区域，建议谨慎。'
  }
  return getLevelStr(data) + '，属于风险低的区域，建议通过。'
}

export const isNormal = (data: any) => {
  const value = data?.meta_index_score
  return value >= 60
}
