import LabeledTitle from '../../../components/LabeledTitle'
import React from 'react'
import ReactECharts from 'echarts-for-react'
import PageEmpty from '../../../components/PageEmpty'
import ItemWrapper from '../../../components/ItemWrapper'

interface RelatedCasesStatisticsProps {
  data: any
}

export default function ZjcRelatedCasesStatistics({ data }: RelatedCasesStatisticsProps) {
  const civilCount = (data?.court_case_info?.civil ?? []).length
  const criminalCount = (data?.court_case_info?.criminal ?? []).length
  const otherCount = (data?.court_case_info?.other ?? []).length
  const option = {
    legend: {
      orient: 'vertical',
      right: 'right',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: civilCount, name: `民事案件${civilCount}个` },
          { value: criminalCount, name: `刑事案件${criminalCount}个` },
          { value: otherCount, name: `其他案件${otherCount}个` },
        ],
      },
    ],
  }
  return (
    <ItemWrapper>
      <LabeledTitle title={'关联案件统计'} />
      {civilCount > 0 || criminalCount > 0 || otherCount > 0 ? (
        <ReactECharts option={option} />
      ) : (
        <PageEmpty image={'/good.png'} title={'暂无数据'} size={80} />
      )}
    </ItemWrapper>
  )
}
