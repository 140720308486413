import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { report } from '../api/orderService'
import { Dialog } from 'antd-mobile'
import Main from './components/Main'

export default function Report() {
  const navigate = useNavigate()
  let { orderId } = useParams()
  const [data, setData] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  useEffect(() => {
    if (orderId === '') {
      navigate('/')
    }
    const fetchData = async (retryDelays = [3000, 2000, 2000, 3000, 5000, 5000, 5000], attempt = 0) => {
      report(orderId!)
        .then((res) => {
          setData(res.data)
          setIsLoading(false)
        })
        .catch((error) => {
          if (error.errorCode === 1011) {
            if (attempt < retryDelays.length) {
              setTimeout(() => fetchData(retryDelays, attempt + 1), retryDelays[attempt])
            } else {
              Dialog.alert({
                content: '报告生成中，请返回报告列表手动刷新查看或者联系客服处理。',
                onConfirm: () => {
                  navigate('/orders')
                },
              })
            }
          }
        })
    }
    fetchData().then()
  }, [navigate, orderId])

  return <Main data={data} isLoading={isLoading} />
}
