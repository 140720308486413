import { Container, Group, Stack, Text } from '@mantine/core'
import React from 'react'

interface HitItemProps {
  name: string
  value: string
  isNormal?: boolean
  hitCount?: number
  recentHitTime?: string
}

export default function HitItem({ name, value, isNormal = true, hitCount = 0, recentHitTime = '-' }: HitItemProps) {
  return (
    <Stack gap={2}>
      <Group justify={'space-between'}>
        <Text size={'sm'}>{name}</Text>
        <Text
          size={'sm'}
          style={{
            color: isNormal ? 'blue' : 'red',
            borderColor: isNormal ? 'blue' : 'red',
            borderWidth: '1px',
            borderStyle: 'solid',
            padding: '2px 4px',
            borderRadius: '4px',
          }}
        >
          {value}
        </Text>
      </Group>
      <Group justify={'space-between'}>
        <Text size={'sm'} c={'dimmed'}>
          命中次数: {hitCount}次
        </Text>
        <Text size={'sm'} c={'dimmed'}>
          最近一次距今时间: {recentHitTime}
        </Text>
      </Group>
    </Stack>
  )
}
