import { Box, Stack, Text } from '@mantine/core'
import React from 'react'

interface ReportItemProps {
  number: number
  item: any
}

export default function ZjcCourtCase({ number, item }: ReportItemProps) {
  return (
    <Box
      bg={'#F1F4FF'}
      p={'xs'}
      style={{
        borderRadius: '4px',
      }}
    >
      <Stack gap={'8'}>
        <Text size={'sm'}>案号: {item?.caseCode}</Text>
        <Text size={'sm'}>案件类型: {item?.caseType}</Text>
        <Text size={'sm'}>诉讼地位: {item?.casePosition}</Text>
        <Text size={'sm'}>法院名称: {item?.courtName}</Text>
        <Text size={'sm'}>立案时间: {item?.caseRegTime}</Text>
        <Text size={'sm'}>结案时间: {item?.caseEndTime}</Text>
        <Text size={'sm'}>案件状态: {item?.caseStatus}</Text>
        <Text size={'sm'}>案由: {item?.caseReason}</Text>
        <Text size={'sm'}>判决结果: {item?.caseResult}</Text>
      </Stack>
    </Box>
  )
}
