import { Flex, LoadingOverlay, Stack, Text, Title } from '@mantine/core'
import Stamp from './Stamp'
import { getModuleNodes } from './ModuleUtil'
import ReportFooter from './ReportFooter'
import React, { useState } from 'react'
import { getStampStr, isNormal } from './ReportUtils'
import RightFloating from '../../components/RightFloating'
import FloatingButton from '../../components/FloatingButton'
import { useNavigate } from 'react-router-dom'
import Share from '../../components/Share'

interface MainProps {
  data: any
  isLoading: boolean
}

export default function Main({ data, isLoading }: MainProps) {
  const navigate = useNavigate()
  const [shareVisible, setShareVisible] = useState<boolean>(false)

  return (
    <div className={'max-w-xl m-auto bg-[#E6EFFF]'} id={'report'}>
      {isLoading && <LoadingOverlay zIndex={1000} visible overlayProps={{ radius: 'sm', blur: 2 }} />}
      <Stack>
        <div className={'relative'}>
          <Flex
            align={'center'}
            style={{
              width: '100%',
              backgroundImage: 'url(../report_bg.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'local',
              padding: '20px',
              height: '240px',
            }}
          >
            <Stack gap={6} c={'white'}>
              <Title order={2}>{data?.meta_info_title || '个人大数据风险报告'}</Title>
              <Text size={'sm'}>{data?.meta_info_sub_title || '精准背景调查 企业信赖之选'}</Text>
            </Stack>
          </Flex>
          <Stamp text={getStampStr(data)} isNormal={isNormal(data)} />
        </div>
        <div className={'mt-[-60px]'}>{getModuleNodes(data)}</div>
        <ReportFooter />
      </Stack>
      <RightFloating>
        <Stack align={'flex-end'}>
          <FloatingButton
            key={'share'}
            onClick={() => {
              setShareVisible(true)
            }}
            image={'../share.png'}
          />
          <FloatingButton
            key={'history'}
            onClick={() => {
              navigate('/orders')
            }}
            image={'../history.png'}
          />
        </Stack>
      </RightFloating>
      <Share shareVisible={shareVisible} setShareVisible={setShareVisible} />
    </div>
  )
}
