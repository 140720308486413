import getCaptcha from 'dynamic-tencent-captcha'
import { useEffect, useState } from 'react'
import { sendSmsCode } from '../api/smsService'
import { SendSmsCodeInterface } from '../typings/types'

const useVerificationCodeTimer = (initialSeconds = 60) => {
  const [secondsLeft, setSecondsLeft] = useState(initialSeconds)
  const [isActive, setIsActive] = useState(false)
  const [label, setLabel] = useState('获取验证码')

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null

    if (isActive && secondsLeft > 0) {
      setLabel(`${secondsLeft}秒后重试`)
      interval = setInterval(() => {
        setSecondsLeft((seconds) => seconds - 1)
      }, 1000)
    } else if (secondsLeft === 0) {
      setIsActive(false)
      setLabel('重新获取')
      setSecondsLeft(initialSeconds)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [isActive, secondsLeft, initialSeconds])

  const startTimer = (value: SendSmsCodeInterface) => {
    if (!isActive) {
      getCaptcha('195603958').then((result) => {
        value.ticket = result.ticket
        value.randstr = result.randstr
        sendSmsCode(value!)
          .then((res) => {
            setIsActive(true)
            setSecondsLeft(initialSeconds)
          })
          .catch(() => {})
      })
    }
  }

  return { label, startTimer, isActive }
}
export default useVerificationCodeTimer
