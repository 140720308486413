import React from 'react'
import { Image, SimpleGrid, Stack, Text } from '@mantine/core'

export default function Partner() {
  const PartnerItem = ({ icon }: { icon: string }) => {
    return (
      <div className={'rounded-[8px] bg-white'}>
        <Image src={icon} w={'210px'} />
      </div>
    )
  }

  const line1 = [
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/bitable/B6gLbTky9oLGswxSrFYcxTZlngc~tplv-jbbdkfciu3-image:0:0.image',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/bitable/CRsbb9McUoA4EKxFISHchZqvnvc~tplv-jbbdkfciu3-image:0:0.image',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/bitable/Sn7sbegMAocLiWx8RBGc2NcEnXf~tplv-jbbdkfciu3-image:0:0.image',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/bitable/UERdb31xhoi806x5OXwc3nKFn9f~tplv-jbbdkfciu3-image:0:0.image',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/bitable/VvZ1bJYAgorMBKxRdJ6cLWkpngd~tplv-jbbdkfciu3-image:0:0.image',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/bitable/G5oKbTKINo4eWjxnyBhcGnPxnfg~tplv-jbbdkfciu3-image:0:0.image',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/bitable/Vdqtb9tAPoSj5Gx3stUckCwGnwc~tplv-jbbdkfciu3-image:0:0.image',
  ]

  const line2 = [
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/bitable/JdyjbouinodlbjxW4bMcZzz4nQh~tplv-jbbdkfciu3-image:0:0.image',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/bitable/FSIsbytwKoPulrxRrSKcPVcsnBh~tplv-jbbdkfciu3-image:0:0.image',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/bitable/O7G1bEVMVo9UaxxrkCZcC2zPnPA~tplv-jbbdkfciu3-image:0:0.image',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/bitable/GBOab8OUSoIkhoxP29HcxCMenKe~tplv-jbbdkfciu3-image:0:0.image',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/bitable/B1NqbdNm8osHbOxEO4kcrVdVnve~tplv-jbbdkfciu3-image:0:0.image',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/bitable/QHUqbqI10oY2aNxPT4OcataAnof~tplv-jbbdkfciu3-image:0:0.image',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/bitable/F6jIbaJvIoJv72x9mxYctBUAnpb~tplv-jbbdkfciu3-image:0:0.image',
  ]

  return (
    <div
      className={'py-[120px]'}
      style={{
        backgroundImage:
          'url(https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/9ad072121ca04b2ca196258444996b96~tplv-jbbdkfciu3-png:0:0.png)',
        backgroundClip: 'padding-box',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'scroll',
        backgroundPosition: 'top center',
        backgroundBlendMode: 'normal',
      }}
    >
      <Stack align={'center'} gap={'120px'}>
        <Text size={'40px'} fw={'500'} c={'#1f2329'}>
          各行各业都有合作伙伴
        </Text>
        <Stack align={'center'}>
          <SimpleGrid cols={7}>
            {line1.map((icon, index) => (
              <PartnerItem key={index} icon={icon} />
            ))}
          </SimpleGrid>
          <SimpleGrid cols={7}>
            {line2.map((icon, index) => (
              <PartnerItem key={index} icon={icon} />
            ))}
          </SimpleGrid>
        </Stack>
      </Stack>
    </div>
  )
}
