import LabeledTitle from '../../../components/LabeledTitle'
import ReportItem from '../ReportItem'
import ItemWrapper from '../../../components/ItemWrapper'

export default function LoanPortfolio({ data }: { data: any }) {
  const loanInfoXypCpl0002 = data?.loan_info_xyp_cpl0002
  return (
    <ItemWrapper>
      <LabeledTitle title={'贷款整体情况'} />
      <ReportItem
        name={'贷款已结清机构数'}
        value={
          loanInfoXypCpl0002 === '0'
            ? '0'
            : loanInfoXypCpl0002 === '1'
              ? '0-5'
              : loanInfoXypCpl0002 === '2'
                ? '5-9'
                : loanInfoXypCpl0002 === '3'
                  ? '9-14'
                  : loanInfoXypCpl0002 === '4'
                    ? '14-17'
                    : '17+'
        }
        isNormal={loanInfoXypCpl0002 === '0'}
      />
      <ReportItem
        name={'信⽤贷款时长(天)'}
        value={
          data?.loan_info_xyp_cpl0045 === '0'
            ? '0'
            : data?.loan_info_xyp_cpl0045 === '1'
              ? '0-220'
              : data?.loan_info_xyp_cpl0045 === '2'
                ? '220-450'
                : data?.loan_info_xyp_cpl0045 === '3'
                  ? '450-520'
                  : '520+'
        }
        isNormal={data?.loan_info_xyp_cpl0045 === '0'}
      />
      <ReportItem
        name={'最近⼀次交易距离当前时间(天)'}
        value={
          data?.loan_info_xyp_cpl0046 === '0'
            ? '0'
            : data?.loan_info_xyp_cpl0046 === '1'
              ? '0-5'
              : data?.loan_info_xyp_cpl0046 === '1'
                ? '5-25'
                : '25+'
        }
        isNormal={data?.loan_info_xyp_cpl0046 === '0'}
      />
      <ReportItem
        name={'累计交易⾦额(元)'}
        value={
          data?.loan_info_xyp_t01aczzzz === '0'
            ? '0'
            : data?.loan_info_xyp_t01aczzzz === '1'
              ? '0-45000'
              : data?.loan_info_xyp_t01aczzzz === '2'
                ? '45000-115000'
                : '115000+'
        }
        isNormal={data?.loan_info_xyp_t01aczzzz === '0'}
      />
      <ReportItem
        name={'因交易能⼒不⾜导致失败的交易金额（最小值）(元)'}
        value={
          data?.loan_info_xyp_t01abzzza === '0'
            ? '0'
            : data?.loan_info_xyp_t01abzzza === '1'
              ? '0-600'
              : data?.loan_info_xyp_t01abzzza === '2'
                ? '600-1300'
                : '1300+'
        }
        isNormal={data?.loan_info_xyp_t01abzzza === '0'}
      />
      <ReportItem
        name={'历史贷款机构成功还款笔数'}
        value={
          data?.loan_info_xyp_cpl0014 === '0'
            ? '0'
            : data?.loan_info_xyp_cpl0014 === '1'
              ? '0-2'
              : data?.loan_info_xyp_cpl0014 === '2'
                ? '2-10'
                : data?.loan_info_xyp_cpl0014 === '3'
                  ? '10-34'
                  : data?.loan_info_xyp_cpl0014 === '4'
                    ? '34-60'
                    : '60+'
        }
        isNormal={data?.loan_info_xyp_cpl0014 === '0'}
      />
      <ReportItem
        name={'历史贷款机构交易失败笔数'}
        value={
          data?.loan_info_xyp_cpl0015 === '0'
            ? '0'
            : data?.loan_info_xyp_cpl0015 === '1'
              ? '0-3'
              : data?.loan_info_xyp_cpl0015 === '2'
                ? '3-5'
                : data?.loan_info_xyp_cpl0015 === '3'
                  ? '5-10'
                  : '10+'
        }
        isNormal={data?.loan_info_xyp_cpl0015 === '0'}
      />
    </ItemWrapper>
  )
}
