import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import ZjcNonBankApplicationRecordsOverview from './ZjcNonBankApplicationRecordsOverview'

interface ZjcNonBankApplicationRecordsProps {
  data: any
  title: string
}

export default function ZjcNonBankApplicationRecords({ data, title }: ZjcNonBankApplicationRecordsProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ZjcNonBankApplicationRecordsOverview data={data} />
    </ReportWrapper>
  )
}
