import { Title } from '@mantine/core'
import { Image } from 'antd-mobile'

interface LabeledTitleProps {
  title: string
  showImage?: boolean
}

export default function LabeledTitle({ title, showImage = true }: LabeledTitleProps) {
  return (
    <div className="flex items-center">
      {showImage && <Image src={`../title-icon.png`} alt={title} width={20} height={20} className={'mr-1'} />}
      <Title order={5}>{title}</Title>
    </div>
  )
}
