import React from 'react'
import { Center, Flex, Group, Image, SimpleGrid, Space, Stack, Text } from '@mantine/core'

export default function Support() {
  const supports = [
    {
      title: '人工服务',
      description1: '7*24h',
      description2: '在线客服解答',
      icon: 'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/2843ad32a3684d22b0b1345034e9d91b~tplv-jbbdkfciu3-png:0:0.png',
    },
    {
      title: '技术支持',
      description1: '工作时段',
      description2: '1v1 在线支持',
      icon: 'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/154aa5547c0143cda6e93985e8d4e9cb~tplv-jbbdkfciu3-png:0:0.png',
    },
    {
      title: '帮助中心',
      description1: '员工自助答疑',
      description2: '降低企业运营成本',
      icon: 'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/12339e4dcb784510be9128d9678a7ca0~tplv-jbbdkfciu3-png:0:0.png',
    },
  ]

  const datas = [
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/f498919284394bb2a0423a8e2765af0a~tplv-jbbdkfciu3-png:0:0.png',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/d3c17f16223048e5a65c50b7d1e2a7b8~tplv-jbbdkfciu3-png:0:0.png',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/1332656975ab41b6aaaad4d2f94d7d12~tplv-jbbdkfciu3-png:0:0.png',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/f8d10015c6c84024b690ac1a52c1c98a~tplv-jbbdkfciu3-png:0:0.png',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/2df68692f1da4c07bbe89f6c725db55d~tplv-jbbdkfciu3-png:0:0.png',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/04ff2c0902d24ed5a3ebdeb73ac0dc31~tplv-jbbdkfciu3-png:0:0.png',
    'https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/0ec8b43395094aefa6b6e02cc1397771~tplv-jbbdkfciu3-png:0:0.png',
  ]
  const SupportItem = ({
    title,
    description1,
    description2,
    icon,
  }: {
    title: string
    badge?: string
    description1: string
    description2: string
    icon: string
  }) => {
    return (
      <div className={'w-[368px] rounded-[16px] bg-white p-[36px]'}>
        <Group align={'flex-start'} justify={'space-between'}>
          <Stack>
            <Text size={'24px'} fw={600}>
              {title}
            </Text>
            <Space />
            <Stack gap={10}>
              <Text size={'18px'} c={'rgb(100, 106, 115)'}>
                {description1}
              </Text>
              <Text size={'18px'} c={'rgb(100, 106, 115)'}>
                {description2}
              </Text>
            </Stack>
          </Stack>
          <Image src={icon} w={'38px'} />
        </Group>
      </div>
    )
  }

  return (
    <div
      style={{
        backgroundImage:
          'url(https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/7f6f9125570349e7bcd33fcf65fadd52~tplv-jbbdkfciu3-png:0:0.png)',
        backgroundClip: 'padding-box',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'scroll',
        backgroundPosition: 'top center',
        backgroundBlendMode: 'normal',
      }}
    >
      <Stack className={'py-16'}>
        <Flex direction={'column'} align={'center'}>
          <Text fw={500} size={'24px'}>
            我们对标国内和国际最⾼标准合规性要求，保护企业的数据安全和隐私，确保数据的合法合规性。
          </Text>
          <Group className={'py-16'}>
            {datas.map((data, index) => (
              <Image key={index} src={data} w={'128px'} />
            ))}
          </Group>
        </Flex>
        <Flex direction={'column'} align={'center'} className={'pt-20 pb-4'}>
          <Text fw={500} size={'40px'}>
            悦享查将为企业和个人提供全方位服务
          </Text>
          <SimpleGrid mt={'48px'} cols={3}>
            {supports.map((support, index) => (
              <SupportItem key={index} {...support} />
            ))}
          </SimpleGrid>
        </Flex>
        <Center>
          <Text size={'14px'} c={'rgb(100, 106, 115)'}>
            我们将根据您使用悦享查的情况提供相应上述服务
          </Text>
        </Center>
      </Stack>
    </div>
  )
}
