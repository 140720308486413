import { Flex, Stack, Title, Text } from '@mantine/core'
import { Image } from 'antd-mobile'
import React from 'react'

interface LabeledContainerProps {
  title: string
}

export default function LabeledContainer({ title }: LabeledContainerProps) {
  return (
    <Stack align="center" justify="center" gap={0}>
      <Text size={'34px'} c={'#D5E1FF'} fw={'bolder'} className={'italic'}>
        BAOGAO
      </Text>
      <Flex gap="xs" justify="center" align="center" direction="row" className={'mt-[-16px]'}>
        <Image src={'../title-left-arrow.png'} alt={title} width={22} height={14} />
        <Title order={3} c={'#184AFE'}>
          {title}
        </Title>
        <Image src={'../title-right-arrow.png'} alt={title} width={22} height={14} />
      </Flex>
    </Stack>
  )
}
