import { useState, useCallback } from 'react'

interface Data {
  meta_info_template_id: string
}

const useViewMode = (data: Data) => {
  const [viewMode, setViewModeState] = useState<'tile' | 'table'>(
    data.meta_info_template_id === '20004' ? 'tile' : 'table'
  )

  const setViewMode = useCallback(
    (newMode: 'tile' | 'table' | ((prevMode: 'tile' | 'table') => 'tile' | 'table')) => {
      if (typeof newMode === 'function') {
        setViewModeState((prevMode) => {
          const calculatedMode = newMode(prevMode)
          return data.meta_info_template_id === '20004' ? 'tile' : calculatedMode
        })
      } else {
        setViewModeState(data.meta_info_template_id === '20004' ? 'tile' : newMode)
      }
    },
    [data.meta_info_template_id]
  )

  return [viewMode, setViewMode] as const
}

export default useViewMode
