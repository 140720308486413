import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import ZjcBankApplicationRecordsOverview from './ZjcBankApplicationRecordsOverview'

interface ZjcBankApplicationRecordsProps {
  data: any
  title: string
}

export default function ZjcBankApplicationRecords({ data, title }: ZjcBankApplicationRecordsProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ZjcBankApplicationRecordsOverview data={data} />
    </ReportWrapper>
  )
}
