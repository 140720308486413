import { Image, Tooltip } from '@mantine/core'
import React from 'react'

export default function Contract({ children }: { children: React.ReactNode }) {
  return (
    <Tooltip color={'#fff'} label={<Image src={'./kefu.png'} h={'300px'} />}>
      {children}
    </Tooltip>
  )
}
