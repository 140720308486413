import React from 'react'
import { Stack, Text, Title } from '@mantine/core'
import Contract from './Contract'

export default function CTA() {
  return (
    <Stack
      className={'py-16'}
      align={'center'}
      gap={30}
      style={{
        background: 'linear-gradient(92deg,rgba(37,176,231,.1) 0,rgba(20,86,240,.1) 51.56%,rgba(159,111,241,.1) 100%)',
      }}
    >
      <Stack gap={20} align={'center'}>
        <Title size={'40px'}>悦享查，让信用更简单</Title>
        <Text c={'#646a73'} size={'18px'}>
          欢迎联系我们，我们将竭诚为您服务，为您提供最合适的解决方案。
        </Text>
      </Stack>
      <section>
        <Contract>
          <button
            style={{
              background: 'linear-gradient(90deg,#4e83fd 0,#3370ff 100%)',
              color: '#fff',
              padding: '10px 20px',
              margin: '10px',
              border: 'none',
              borderRadius: '40px',
              width: '176px',
              fontWeight: '500',
              fontSize: '20px',
            }}
          >
            联系我们
          </button>
        </Contract>
        <button
          style={{
            color: '#336df4',
            padding: '10px 20px',
            margin: '10px',
            border: '1px solid #4e83fd',
            borderRadius: '40px',
            width: '176px',
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          下载APP
        </button>
      </section>
    </Stack>
  )
}
