import React from 'react'
import GaugeComponent from 'react-gauge-component'
import { getLevelStr } from '../ReportUtils'

interface ReportScoreProps {
  data: any
}

export default function ReportScore({ data }: ReportScoreProps) {
  return (
    <GaugeComponent
      value={data?.meta_index_score}
      type="semicircle"
      labels={{
        tickLabels: {
          type: 'outer',
          ticks: [
            {
              value: 0,
              valueConfig: {
                formatTextValue: (value: any) => '0',
                style: {
                  color: '#184AFE',
                },
              },
            },
            {
              value: 25,
              valueConfig: {
                formatTextValue: (value: any) => '25',
                style: {
                  color: '#184AFE',
                },
              },
            },
            {
              value: 50,
              valueConfig: {
                formatTextValue: (value: any) => '50',
                style: {
                  color: '#184AFE',
                },
              },
            },
            {
              value: 75,
              valueConfig: {
                formatTextValue: (value: any) => '75',
                style: {
                  color: '#184AFE',
                },
              },
            },
            {
              value: 100,
              valueConfig: {
                formatTextValue: (value: any) => '100',
                style: {
                  color: '#184AFE',
                },
              },
            },
          ],
        },
        valueLabel: {
          formatTextValue: (value: any) => {
            // 租赁报告显示等级
            return data.meta_info_template_id === '20004' ? getLevelStr(data) : value
          },
          matchColorWithArc: true,
          style: {
            fontSize: '24px',
          },
        },
      }}
      arc={{
        colorArray: ['#C8D4FF', '#8CA6FF', '#5376E9', '#184AFE'],
        subArcs: [{}, {}, {}, {}],
        padding: 0.02,
        width: 0.3,
      }}
      pointer={{ type: 'arrow', elastic: true, color: '#184AFE' }}
    />
  )
}
