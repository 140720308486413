import { Anchor, Group, Stack, Text } from '@mantine/core'
import React from 'react'

export default function Footer() {
  return (
    <div className="bg-[#1F2329] bg-cover text-[#bbbfc4]">
      <Stack px={'52px'} py={'40px'} maw={'1240px'} mx={'auto'}>
        <Group>
          <Text size={'sm'}>Copyright © 2024 四川陨石科技有限公司</Text>
          <Anchor size={'sm'} href="https://beian.miit.gov.cn/" target="_blank">
            蜀ICP备2024089652号-1
          </Anchor>
        </Group>
      </Stack>
    </div>
  )
}
