import { SimpleGrid, Stack, Text } from '@mantine/core'
import React, { useState } from 'react'
import Contract from './Contract'

export default function Feature() {
  const [selected, setSelected] = useState(0)

  const features = [
    {
      title: '风险评估评分',
      desc: '根据自然人姓名及身份证号码查询其风险评分',
    },
    {
      title: '手机三要素核验',
      desc: '根据比对姓名、身份证号及手机号的一致性，核验手机用户身份信息的真伪',
    },
    {
      title: '多头借贷',
      desc: '根据自然人身份证、姓名、手机号，查询自然人多头借贷信息',
    },
    {
      title: '异常名单',
      desc: '查询自然人是否存在银行失联欺诈行为和网失联欺诈行为',
    },
    {
      title: '不良信息',
      desc: '查询自然人的不良信息，包括个人涉赌风险、涉毒风险、在逃风险，涉案风险等信息',
    },
    {
      title: '案件信息',
      desc: '查询自然人的刑事案件、民事案件、失信案件、执行案件行政处罚等信息',
    },
    {
      title: '限高消费',
      desc: '查询自然人的限高消费信息',
    },
    {
      title: '社交风险标签',
      desc: '查询自然人的社交风险标签信息',
    },
  ]

  const FeatureItem = ({
    title,
    desc,
    index,
    isSelected,
    onSelected,
  }: {
    index: number
    title: string
    desc: string
    isSelected: boolean
    onSelected: (index: number) => void
  }) => {
    return (
      <Stack
        gap={20}
        onClick={() => onSelected(index)}
        style={{
          background: isSelected ? '#fff' : 'none',
          padding: '24px',
          boxShadow: isSelected ? '0 10px 30px 0 rgba(176,191,231,.15)' : 'none',
          borderRadius: '16px',
          cursor: 'pointer',
        }}
      >
        <Text
          size={'24px'}
          fw={600}
          style={{
            color: isSelected ? '#4e83fd' : '#1f2329',
          }}
        >
          {title}
        </Text>
        <Text size={'16px'} c={'rgb(100, 106, 115)'}>
          {desc}
        </Text>
      </Stack>
    )
  }
  return (
    <Stack align={'center'} gap={'120px'} className={'mb-[120px]'}>
      <Text size={'40px'} fw={'500'} c={'#1f2329'}>
        多种维度，满足您的任何需求
      </Text>
      <SimpleGrid cols={2} spacing={'50px'}>
        {features.map((feature, index) => (
          <FeatureItem
            key={index}
            index={index}
            title={feature.title}
            desc={feature.desc}
            isSelected={selected === index}
            onSelected={(index) => {
              setSelected(index)
            }}
          />
        ))}
        <Contract>
          <button
            style={{
              background: 'linear-gradient(90deg,#4e83fd 0,#3370ff 100%)',
              color: '#fff',
              padding: '10px 20px',
              margin: '10px',
              border: 'none',
              borderRadius: '40px',
              width: '176px',
              fontWeight: '500',
              fontSize: '20px',
            }}
          >
            联系我们
          </button>
        </Contract>
      </SimpleGrid>
    </Stack>
  )
}
