import React from 'react'
import ReportItem from '../ReportItem'
import LabeledContainer from '../../../components/LabeledContainer'
import ReportWrapper from '../ReportWrapper'
import ItemWrapper from '../../../components/ItemWrapper'
import { Flex, Stack, Text, Title } from '@mantine/core'

interface BasicDetailProps {
  data: any
  title: string
}

export default function BasicDetail({ data, title }: BasicDetailProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ItemWrapper>
        <Stack>
          <Title order={2}>{data?.basic_info_name}</Title>
          <Flex className={'bg-[#E8F0FF] rounded-xl px-10 py-3'} w={'100%'} justify={'space-between'}>
            <Stack gap={2} align={'center'}>
              <Text c={'gray'}>性别</Text>
              <Text>{data?.basic_info_sex === 1 ? '男' : '女'} </Text>
            </Stack>
            <Stack gap={2} align={'center'}>
              <Text c={'gray'}>年龄</Text>
              <Text>{data?.basic_info_age}</Text>
            </Stack>
            <Stack gap={2} align={'center'}>
              <Text c={'gray'}>星座</Text>
              <Text>{data?.basic_info_constellation}</Text>
            </Stack>
          </Flex>
          <ReportItem name={'身份证号'} value={data?.basic_info_id_card} />
          <ReportItem name={'身份证发放地'} value={data?.basic_info_id_card_zone} />
          <ReportItem name={'手机号'} value={data?.basic_info_phone} />
          <ReportItem name={'手机号归属地'} value={data?.basic_info_phone_zone} />
          <ReportItem
            name={'手机号检测结果'}
            isNormal={data?.basic_info_check === '1'}
            value={
              data?.basic_info_check === '1' ? '被查询人姓名与运营商提供的一致' : '被查询人姓名与运营商提供的不一致'
            }
          />
        </Stack>
      </ItemWrapper>
    </ReportWrapper>
  )
}
