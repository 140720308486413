import LabeledTitle from '../../../components/LabeledTitle'
import ReportItem from '../ReportItem'
import React from 'react'
import ItemWrapper from '../../../components/ItemWrapper'

export default function PriorityRisk({ data }: { data: any }) {
  return (
    <ItemWrapper>
      <LabeledTitle title={'重点风险扫描'} />
      <ReportItem
        name={'涉赌风险'}
        value={data?.bad_info_detail_score_B !== '0' ? '存在风险' : '不存在风险'}
        isNormal={data?.bad_info_detail_score_B === '0'}
      />
      <ReportItem
        name={'涉毒风险'}
        value={data?.bad_info_detail_score_C !== '0' ? '存在风险' : '不存在风险'}
        isNormal={data?.bad_info_detail_score_C === '0'}
      />
      <ReportItem
        name={'在逃风险'}
        value={data?.bad_info_detail_score_D !== '0' ? '存在风险' : '不存在风险'}
        isNormal={data?.bad_info_detail_score_D === '0'}
      />
      <ReportItem
        name={'涉案风险'}
        value={data?.bad_info_detail_score_E !== '0' ? '存在风险' : '不存在风险'}
        isNormal={data?.bad_info_detail_score_E === '0'}
      />
      <ReportItem
        name={'重点人员风险'}
        value={data?.bad_info_detail_score_F !== '0' ? '存在风险' : '不存在风险'}
        isNormal={data?.bad_info_detail_score_F === '0'}
      />
      <ReportItem
        name={'涉黑涉恐风险'}
        value={data?.bad_info_detail_score_G !== '0' ? '存在风险' : '不存在风险'}
        isNormal={data?.bad_info_detail_score_G === '0'}
      />
    </ItemWrapper>
  )
}
