import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportItem from '../ReportItem'
import ItemWrapper from '../../../components/ItemWrapper'

export default function BadInformationDetection({ data, title }: { data: any; title: string }) {
  const badInfoScore = parseInt(data?.bad_info_score)
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ItemWrapper>
        <ReportItem
          name={'是否命中不良'}
          value={parseInt(data?.bad_info_hit) === 1 ? '是' : '否'}
          isNormal={parseInt(data?.bad_info_hit) === 0}
        />
        <ReportItem
          name={'不良等级'}
          value={
            badInfoScore >= 80
              ? '严重不良记录'
              : badInfoScore >= 60
                ? '中度不良记录'
                : badInfoScore >= 40
                  ? '一般不良记录'
                  : badInfoScore >= 20
                    ? '涉稳、涉恐不良记录'
                    : badInfoScore > 0
                      ? '其他'
                      : '无不良信息'
          }
          isNormal={badInfoScore < 20}
        />
      </ItemWrapper>
    </ReportWrapper>
  )
}
