import React from 'react'
import { ResultPage } from 'antd-mobile'

export default function RechargeSuccess() {
  return (
    <ResultPage
      status="success"
      title="充值成功"
      description="恭喜您充值成功！充值明细请在悦享查App中查看。"
      style={{
        '--background-color': '#5087EB',
      }}
    ></ResultPage>
  )
}
