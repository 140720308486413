import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import LabeledTitle from '../../../components/LabeledTitle'
import ZjcFailureComplyCase from './ZjcFailureComplyCase'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'
import ItemWrapper from '../../../components/ItemWrapper'
import { Stack } from '@mantine/core'

interface FailureComplyCasesProps {
  data: any
  title: string
}

export default function ZjcFailureComplyCases({ data, title }: FailureComplyCasesProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ItemWrapper>
        <Stack>
          <LabeledTitle title={'失信案件'} />
          <ExpandableListWrapper
            isEmpty={(data?.dishonest_case_info ?? []).length === 0 || data?.dishonest_case_info === undefined}
          >
            {(data?.dishonest_case_info ?? []).map((item: any, index: number) => {
              return <ZjcFailureComplyCase number={index + 1} key={index} item={item} />
            })}
          </ExpandableListWrapper>
        </Stack>
      </ItemWrapper>
    </ReportWrapper>
  )
}
