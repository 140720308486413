import React from 'react'
import { ResultPage } from 'antd-mobile'
import { Button, Center } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'

export default function Success() {
  return (
    <ResultPage
      status="success"
      title="注册成功"
      description="恭喜您注册成功，现在可以通过各大应用市场下载悦享查APP进行使用了！"
      style={{
        '--background-color': '#5087EB',
      }}
    >
      <Center className="mt-[-12px]">
        <Button
          variant="filled"
          color="#000"
          radius="lg"
          leftSection={<IconDownload />}
          onClick={() => {
            window.location.href = 'https://www.pgyer.com/yuexiangcha'
          }}
        >
          下载APP
        </Button>
      </Center>
    </ResultPage>
  )
}
