import React from 'react'
import { Box, Image } from '@mantine/core'
import RechargeForm from './components/RechargeForm'
import { Helmet } from 'react-helmet'

export default function Recharge() {
  return (
    <div className={'max-w-xl m-auto'}>
      <Helmet>
        <title>悦享查-代理商充值</title>
      </Helmet>
      <Box className="bg-[#5087EB] pb-4 h-screen">
        <Image src="./query_bg.png" />
        <div className="mx-4 bg-white rounded-3xl px-6 py-5 mb-4">
          <RechargeForm />
        </div>
      </Box>
    </div>
  )
}
