import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import LabeledTitle from '../../../components/LabeledTitle'
import ReportItem from '../ReportItem'
import ItemWrapper from '../../../components/ItemWrapper'
import { Stack } from '@mantine/core'

interface SocialTagProps {
  data: any
  title: string
}

export default function SocialTag({ data, title }: SocialTagProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ItemWrapper>
        <Stack>
          <LabeledTitle title={'社交标签'} />
          <ReportItem
            name={'是否与信贷中介有关联'}
            value={data?.risk_tag_xdzjgl === 1 ? '是' : '否'}
            isNormal={data?.risk_tag_xdzjgl !== 1}
          />
          <ReportItem
            name={'是否疑似与高风险行业有关联'}
            value={data?.risk_tag_gfxhy === 1 ? '是' : '否'}
            isNormal={data?.risk_tag_gfxhy !== 1}
          />
          <ReportItem
            name={'是否疑似虚假资料'}
            value={data?.risk_tag_xjzl === 1 ? '是' : '否'}
            isNormal={data?.risk_tag_xjzl !== 1}
          />
          <ReportItem
            name={'是否疑似羊毛党'}
            value={data?.risk_tag_ymd === 1 ? '是' : '否'}
            isNormal={data?.risk_tag_ymd !== 1}
          />
          <ReportItem
            name={'是否身份信息存疑'}
            value={data?.risk_tag_sfxxcy === 1 ? '是' : '否'}
            isNormal={data?.risk_tag_sfxxcy !== 1}
          />
          <ReportItem
            name={'是否高风险'}
            value={data?.risk_tag_gfx === 1 ? '是' : '否'}
            isNormal={data?.risk_tag_gfx !== 1}
          />
          <ReportItem
            name={'是否存在失信行为'}
            value={data?.risk_tag_sxxw === 1 ? '是' : '否'}
            isNormal={data?.risk_tag_sxxw !== 1}
          />
          <ReportItem
            name={'是否存在支付异常行为'}
            value={data?.risk_tag_zfyc === 1 ? '是' : '否'}
            isNormal={data?.risk_tag_zfyc !== 1}
          />
          <ReportItem
            name={'是否存在其他异常行为'}
            value={data?.risk_tag_qtyc === 1 ? '是' : '否'}
            isNormal={data?.risk_tag_qtyc !== 1}
          />
          <ReportItem
            name={'是否上网环境异常'}
            value={data?.risk_tag_swhjyc === 1 ? '是' : '否'}
            isNormal={data?.risk_tag_swhjyc !== 1}
          />
        </Stack>
      </ItemWrapper>
    </ReportWrapper>
  )
}
