import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportItem from '../ReportItem'
import PageEmpty from '../../../components/PageEmpty'
import ItemWrapper from '../../../components/ItemWrapper'

export default function ZjcBadInformationDetection({ data, title }: { data: any; title: string }) {
  const badInfoScore = parseInt(data?.bad_info_score)

  const badInfoNode: React.JSX.Element = (
    <ItemWrapper>
      <ReportItem
        name={'是否命中不良'}
        value={parseInt(data?.bad_info_hit) === 1 ? '是' : '否'}
        isNormal={parseInt(data?.bad_info_hit) === 0}
      />
      <ReportItem
        name={'不良等级'}
        value={
          badInfoScore > 60
            ? '未命中重点人'
            : badInfoScore === 60
              ? '命中正常人'
              : badInfoScore >= 20
                ? '命中重点人'
                : badInfoScore >= 10
                  ? '命中重点人，姓名不一致'
                  : '其他'
        }
        isNormal={badInfoScore < 20}
      />
    </ItemWrapper>
  )
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ItemWrapper>
        {parseInt(data?.bad_info_hit) === 1 ? (
          badInfoNode
        ) : (
          <PageEmpty image={'/good.png'} title={'未命中'} size={80} />
        )}
      </ItemWrapper>
    </ReportWrapper>
  )
}
