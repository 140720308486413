import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import LabeledTitle from '../../../components/LabeledTitle'
import LimitHighConsumptionCase from './LimitHighConsumptionCase'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'
import ItemWrapper from '../../../components/ItemWrapper'
import { Stack } from '@mantine/core'

interface LimitHighConsumptionCasesProps {
  data: any
  title: string
}

export default function LimitHighConsumptionCases({ data, title }: LimitHighConsumptionCasesProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ItemWrapper>
        <Stack>
          <LabeledTitle title={'限高执行'} />
          <ExpandableListWrapper
            isEmpty={(data?.limited_case_info ?? []).length === 0 || data?.limited_case_info === undefined}
          >
            {(data?.limited_case_info ?? []).map((item: any, index: number) => {
              return <LimitHighConsumptionCase key={index} number={index + 1} item={item} />
            })}
          </ExpandableListWrapper>
        </Stack>
      </ItemWrapper>
    </ReportWrapper>
  )
}
