import React from 'react'
import LabeledTitle from '../../../components/LabeledTitle'
import ReportItem from '../ReportItem'
import ItemWrapper from '../../../components/ItemWrapper'

export default function CriminalRecordRisk({ data }: { data: any }) {
  return (
    <ItemWrapper>
      <LabeledTitle title={'前科风险扫描'} />
      <ReportItem
        name={'金融风险'}
        value={data?.bad_info_detail_score_A_1 !== '0' ? '存在风险' : '不存在风险'}
        isNormal={data?.bad_info_detail_score_A_1 === '0'}
      />
      <ReportItem
        name={'侵犯他人财产风险'}
        value={data?.bad_info_detail_score_A_2 !== '0' ? '存在风险' : '不存在风险'}
        isNormal={data?.bad_info_detail_score_A_2 === '0'}
      />
      <ReportItem
        name={'人身权利风险'}
        value={data?.bad_info_detail_score_A_3 !== '0' ? '存在风险' : '不存在风险'}
        isNormal={data?.bad_info_detail_score_A_3 === '0'}
      />
      <ReportItem
        name={'涉赌风险'}
        value={data?.bad_info_detail_score_A_4 !== '0' ? '存在风险' : '不存在风险'}
        isNormal={data?.bad_info_detail_score_A_4 === '0'}
      />
      <ReportItem
        name={'安全危害风险'}
        value={data?.bad_info_detail_score_A_5 !== '0' ? '存在风险' : '不存在风险'}
        isNormal={data?.bad_info_detail_score_A_5 === '0'}
      />
      <ReportItem
        name={'公共妨碍风险'}
        value={data?.bad_info_detail_score_A_6 !== '0' ? '存在风险' : '不存在风险'}
        isNormal={data?.bad_info_detail_score_A_6 === '0'}
      />
      <ReportItem
        name={'其他风险'}
        value={data?.bad_info_detail_score_A_7 !== '0' ? '存在风险' : '不存在风险'}
        isNormal={data?.bad_info_detail_score_A_7 === '0'}
      />
    </ItemWrapper>
  )
}
