import React, { useEffect, useState } from 'react'
import '../App.css'
import QueryReportForm from './components/QueryReportForm'
import { Image, Stack } from '@mantine/core'
import ReportElements from './components/ReportElements'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { facade } from '../api/orderService'
import { TemplateMerchantInterface } from '../typings/orderTypes'
import RightFloating from '../components/RightFloating'
import FloatingButton from '../components/FloatingButton'
import InterceptorWrapper from '../components/InterceptorWrapper'

export default function QueryReport() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const linkId = searchParams.get('linkId') || ''
  const [template, setTemplate] = useState<TemplateMerchantInterface>()

  useEffect(() => {
    if (linkId === '') {
      navigate('/login')
      return
    }
    facade(linkId)
      .then((res) => {
        setTemplate(res)
      })
      .catch(() => {})
  }, [linkId, navigate])

  return (
    <InterceptorWrapper>
      <div className="max-w-xl m-auto">
        <div className="bg-[#5087EB] pb-4">
          <Image src="./query_bg.png" />
          <div className="mx-4 bg-white rounded-3xl px-6 py-5 mb-4">
            <QueryReportForm template={template} />
          </div>
          <div className="mx-4 bg-white rounded-3xl px-6 py-5">
            <ReportElements />
          </div>
          <RightFloating>
            <Stack align={'flex-end'}>
              <FloatingButton
                image={'./history.png'}
                onClick={() => {
                  navigate('/orders')
                }}
              />
            </Stack>
          </RightFloating>
        </div>
      </div>
    </InterceptorWrapper>
  )
}
