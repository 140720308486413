import LabeledTitle from '../../../components/LabeledTitle'
import React from 'react'
import ReportItem from '../ReportItem'
import ItemWrapper from '../../../components/ItemWrapper'

export default function LendingInstitution({ data }: { data: any }) {
  const totalCount = data?.loan_info_xyp_cpl0001
  const consumerCount = data?.loan_info_xyp_cpl0007
  const onlineLoanCount = data?.loan_info_xyp_cpl0008
  return (
    <ItemWrapper>
      <LabeledTitle title={'贷款机构'} />
      <ReportItem
        name={'贷款总机构数'}
        value={totalCount === '0' ? '0' : totalCount === '1' ? '0-9' : totalCount === '2' ? '9-14' : '15+'}
        isNormal={totalCount === '0'}
      />
      <ReportItem
        name={'消费金融类机构数（有场景的、分期）'}
        value={
          consumerCount === '0'
            ? '0'
            : consumerCount === '1'
              ? '0-1'
              : consumerCount === '2'
                ? '1-2'
                : consumerCount === '3'
                  ? '2-4'
                  : '4+'
        }
        isNormal={consumerCount === '0'}
      />
      <ReportItem
        name={'网络贷款类机构数（现金贷）'}
        value={
          onlineLoanCount === '0'
            ? '0'
            : onlineLoanCount === '1'
              ? '0-1'
              : onlineLoanCount === '2'
                ? '1-5'
                : onlineLoanCount === '3'
                  ? '5-11'
                  : '11+'
        }
        isNormal={onlineLoanCount === '0'}
      />
    </ItemWrapper>
  )
}
